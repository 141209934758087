<template>
  <div id="qrcode" ref="qrcode">
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import QRCode from "qrcodejs2";

export default defineComponent({
  props: {
    url: { type: String, default: "error" }
  },
  setup(props) {
    const qrcode = ref(null)

    watch(qrcode, () => {
      createQrcode(qrcode.value, props.url)
    })
    return { qrcode }
  },
  methods: {

  },
  mounted() {
    // this.$nextTick(() => {
    //   this.createQrcode(this.url)
    // })
  }
})

const createQrcode = function(target, url) {
  console.log('url = ', url)
  new QRCode(target, {
    text: url || 'error',
    width: 128,
    height: 128,
    colorDark: '#000000',
    colorLight: '#ffffff',
    correctLevel: QRCode.CorrectLevel.H
  })
}
</script>

<style scoped>

</style>
