<template>
  <div class="block-footer">
    <div class="footer-container">
      <div class="footer-wrap">
        <div class="footer-block footer-block-item">
          <div class="footer-block-title">
            <span style="margin-right: 10px; width: 3px; height: 14px; background-color: #f5bb05!important;display: block!important;"></span>
            {{ $t('footer.default[0]') }}
          </div>
          <div class="footer-block-content">
            <a :href="`mailto:${settings.kefu_email}`" target="_blank" v-if="settings.kefu_email">
              {{ $t('footer.default[1]', { address: settings.kefu_email } ) }}
            </a>
            <a :href="`mailto:${settings.cooperation_email}`" target="_blank"  v-if="settings.cooperation_email">
              {{ $t('footer.default[2]', { address: settings.cooperation_email }) }}
            </a>
            <a :href="`mailto:${settings.join_us}`" target="_blank"  v-if="settings.join_us">
            {{ $t('footer.default[3]', { address: settings.join_us }) }}
            </a>
          </div>
        </div>
        <div class="footer-block footer-global">
          <div class="footer-block-title">
            <span style="margin-right: 10px; width: 3px; height: 14px; background-color: #f5bb05!important;display: block!important;"></span>
            {{ $t('footer.default[4]') }}
          </div>
          <div class="footer-block-img">
            <img src="../assets/images/global.png" alt="global postion" width="281" height="182">
          </div>
          <div class="footer-block-dropdown" style="display: none;">
            <a-dropdown>
              <div class="ant-dropdown-link">
                <img src="../assets/images/global-site.png" alt="global site" height="15">
                <span style="margin-left:10px;" >
                  {{ $t('footer.default[5]') }}（Global）
                </span>
                <DownOutlined />
              </div>
            </a-dropdown>
          </div>
        </div>
      </div>

      <div class="footer-bar" style="margin-top: 30px;border-bottom: 1px solid rgba(124, 136, 160, 0.1);">
      </div>
      <div class="footer-logo" style="margin-top: 40px;">
        <img :src="settings.domain + settings.footerBarLogo" alt="footer logo" height="26">
      </div>
      <div class="footer-logo-text">
        {{ $t('footer.default[7]') }}
      </div>
      <div class="footer-copyright">
        © {{ settings.botttom_time }} {{ settings.name }} {{ $t('footer.default[8]') }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref } from 'vue'
import { DownOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'Footer',
  components: { DownOutlined },
  setup() {
    const settings = inject('settings', ref({}))
    return { settings }
  }
})
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';
.block-footer {
  width: 100%;
  background-color: #151826!important;
  .footer-container {
    width: 1280px;
    padding-top: 60px;
    color: #fff;
    margin: 0 auto;
    .footer-wrap {
      display: flex;
      .footer-block-item {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .footer-global {
        width: 380px;
        position: relative;
        .footer-block-img {
          margin-top: 35px;
          margin-left: 40px;
        }
        .footer-block-dropdown {
          width: 200px;
          top: -7px;
          right: 0;
          border: 1px solid rgba(124,136,160,.34);
          position: absolute;
          cursor: pointer;
          border-radius: 4px;
          padding: 7px 10px 7px 15px;
          &:hover {
            color: $brandColor;
          }
        }
      }
      .footer-block {
        .footer-block-title {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
        .footer-block-content {
          display: flex;
          flex-direction: column;
          text-align: left;
          a {
            color: #fff;
            margin-bottom: 20px;
            &:hover {
              color: $brandColor;
            }
          }
        }
      }
    }
    .footer-logo-text {
      word-break: break-word;
      white-space: normal;
      color: #fff;
      line-height: 1.5;
      margin-top: 20px;
    }
    .footer-copyright {
      padding-top: 30px;
      padding-bottom: 60px;
      font-size: 12px;
      color: hsla(0,0%,100%,.5);
    }
  }
}
</style>
