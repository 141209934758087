module.exports = {
    title: 'CoinBene | Bitcoin, Ripple, Ethereum and more with Trust and Safety',
    banner: {
        default: [
            'White paper',
            'Login',
            'Sign Up'
        ]
    },
    footer: {
        default: [
            'Contact',
            'Support: {address}',
            'Listing Application: {address}',
            'Join Us: {address}',
            'Site',
            'Global',
            'Brazil',
            'The World\'s Leading Trustworthy Crypto Assets Platform',
            'Limited'
        ]
    },
    news: {
        default: [
            'More',
            'Announcement on {appName}\'s First Listing of EVAL DEFI (EVF)'
        ]
    },
    homePage: {
        currencyRank: [
            'Hot Currency Rank',
            'Currency',
            'Trade pair',
            'Last Price',
            'Change',
            '24H Vol',
            'Operation',
            'Trade',
            'More'
        ],
        label: [
            '{appName}Global',
            'Filled Orders(last 24h)',
            'Cover 180+ Countries on Globe, Supply Millions of Users with Safe & Reliable Services on Crypto Asset Trading and Managing',
            'Global quality exchange',
            'CoinMarketCap Certified Rank',
            '1 Million Orders/s High-Performance Engine Matching System',
            '3-Year Well known Brand',
            '5 Million+ Users',
            'HSM Safeguard(Bank Level)',
            'Professional Distributed Frame、System Stop from DDOS Attack',
            'International',
            'Global Ecosystem',
            'Set Local-Custom Operation Service'
        ],
        country: [
            'United States of American',
            'Argentina',
            'Brazil',
            'United Kingdom',
            'Vietnam',
            'Hongkong',
            'Russia',
            'Japan',
            'Korea',
            'Singapore',
            'Dubai'
        ],
        trade: [
            'Multi-Business Trading',
            'Exchange',
            '7/24h Online Trading',
            'Lower Gap  Higher Liquidity',
            'More Qualified New Coins to get Listed',
            'Trade now',
            'Options Trading',
            'Procedure rate is low, market - making commission is high',
            'The world\'s first partner trading platform',
        ],
        download: [
            'Download APP',
            'Support iOS, Android, Google Play; Multi-Platform, trade on APP at Any Time',
        ]
    }
}
