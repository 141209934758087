<template>
  <div>
    <div  id="container" ref="container">
    </div>
  </div>
</template>

<script>
const Highcharts = require('highcharts')
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    ref(0)
  },
  props: {
    list: { type: Array }
  },
  data() {
    return {
      nums: []
    }
  },
  mounted() {
    this.nums = this.list.map(i => Number(i))
    this.init()
  },
  methods: {
    init() {
      Highcharts.chart(this.$refs.container, {
        chart: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          margin: [0, 0, 0, 0],
          type: 'areaspline',
          style: {
            fontFamily: "",
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#006cee'
          },
        },
        title: {
          text: null
        },
        xAxis: {
          tickWidth: 0,
          tickLength: 0,
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: null
          },
        },
        yAxis: {
          tickWidth: 0,
          tickLength: 0,
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: null
          },
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            data: this.nums
          }
        ],
        credits:{
          enabled: false // 禁用版权信息
        },
        plotOptions: {
          areaspline: {
            allowPointSelect: false,
            enableMouseTracking: false,
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false
                },
                select: {
                  enabled: false
                }
              }
            },
            lineColor: '#35b65a',
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                // [0, Highcharts.getOptions().colors[1]],
                // [1, Highcharts.Color(Highcharts.getOptions().colors[1]).setOpacity(0).get('rgba')]
                [0, Highcharts.Color('#35b65a').setOpacity(.8).get('rgba')],
                [1, Highcharts.Color('#35b65a').setOpacity(0).get('rgba')]
              ]
            }
          }
        },

      })
    }
  }
})
</script>

<style lang="scss" scoped>
#container {
  width: 80px;
  height: 25px;
}
</style>
