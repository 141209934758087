<template>
  <div class="home">
    <div style="position: relative">
      <a-carousel @after-change="onChange" dotsClass="banner-dot" autoplay>
        <div
            v-for="(item, index) in bannerList"
            :key="index"
        >
          <div class="banner-item"
               :style="{'background-image': `url(${item.banner})`}">
          </div>
        </div>
      </a-carousel>
      <div>
        <news></news>
      </div>
    </div>
    <div>
      <div class="page-markets">
        <div class="market-range" style="height: 140px; width: 100%;">
          <div class="banner-tickers">
            <div class="banner-ticker-item" v-for="(item, index) in currencyMatchList.slice(0, 6)" :key="index">
              <div class="banner-ticker-wrap">
                <div class="banner-ticker-column">
                  <div class="symbol-name">
                    <img :src="settings.domain + item.base_currency.logo" alt="BTC/USDT" style="width: 20px; height: 20px; margin-right: 5px;">
                    {{ item.symbol }}
                  </div>
                  <div class="symbol-price">
                    {{ item.currency_quotation.close }}
                  </div>
                  <div class="symbol-volume">{{ item.currency_quotation.volume }}</div>
                </div>
                <div class="banner-ticker-column">
                  <div class="banner-ticker-change"
                       :class="[item.currency_quotation.change.startsWith('-') ? 'bg-down' : 'bg-up' ]"
                  >{{ formatChange(item.currency_quotation.change) }}%</div>
                  <div>
                    <Line :list="mockData[0].data" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="market-top">
          <div class="top-container" style="padding-top: 40px; box-sizing: content-box;">
            <div class="home-panel">
              <div class="home-panel-title">
                {{ $t('homePage.currencyRank[0]') }}
              </div>
              <div class="symbol-list">
                <div class="panel-subheader">
                  <div class="head-subs">
                    <div class="subheader-item">
                      {{ $t('homePage.currencyRank[1]') }}
                    </div>
                    <!-- <div class="subheader-item">
                      {{ $t('homePage.currencyRank[2]') }}
                    </div> -->
                    <div class="subheader-item">
                      {{ $t('homePage.currencyRank[3]') }}
                    </div>
                    <div class="subheader-item">
                      {{ $t('homePage.currencyRank[4]') }}
                    </div>
                    <div class="subheader-item">
                      {{ $t('homePage.currencyRank[5]') }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="symbol-list-item" style="height: 65px" v-for="item in currencyMatchList" :key="item.id">
                    <div class="symbol-list-item-container">
                      <div class="list-item-wrap">
                        <div class="ticker-item">
                          <span>{{ item.base_currency_code }}</span>
                        </div>
                        <!-- <div class="ticker-item">
                          <span>{{ item.symbol }}</span>
                        </div> -->
                        <div class="ticker-item">
                          <span>{{ item.currency_quotation.close }}</span>
                        </div>
                        <div class="ticker-item">
                          <div class="ticker-label" :class="[item.currency_quotation.change.startsWith('-') ? 'bg-down' : 'bg-up' ]">
                            {{ formatChange(item.currency_quotation.change) }}
                          </div>
                        </div>
                        <div class="ticker-item">
                          <span>{{ item.currency_quotation.volume }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="show-more">
                <span>
                  {{ $t('homePage.currencyRank[6]') }}
                </span>
                <ArrowRightOutlined />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-body" style="margin-top: -90px; padding-top: 190px;">
      <div>
        <div class="bg-body-column">
          <div>
            <div class="column-text-title">
              {{ $t('homePage.label[0]', { appName: settings.name }) }}
            </div>
            <div class="column-title">
              <div class="text-title">
                {{ $t('homePage.label[1]') }}
              </div>
            </div>
            <div class="column-title">
              <span class="volume">
                {{ getVolumeText(volume) || '$5,872,105,141' }}
              </span>
            </div>
            <div class="column-text" style="margin-bottom: 70px;padding:0 20px">
              {{ $t('homePage.label[2]') }}
            </div>
          </div>
        </div>
        <div class="bg-body-column2" style="width: 100%; margin: 0 auto; height: 100%;padding:0 20px">
          <div class="column-item">
            <img src="../assets/images/icon-rank.png" alt="rank" width="80" height="80" style="text-align: center; margin: 0px auto; display: block;">
            <div class="column-item-title">
              {{ $t('homePage.label[3]') }}
            </div>
            <div class="column-item-text">
              {{ $t('homePage.label[4]') }}
            </div>
            <div class="column-item-text">
              {{ $t('homePage.label[5]') }}
            </div>
          </div>
          <div class="column-item">
            <img src="../assets/images/rank-circle.png" alt="rank" width="80" height="80" style="text-align: center; margin: 0px auto; display: block;">
            <div class="column-item-title">
              {{ $t('homePage.label[6]') }}
            </div>
            <div class="column-item-text">
              {{ $t('homePage.label[7]') }}
            </div>
            <div class="column-item-text">
              {{ $t('homePage.label[8]') }}
            </div>
            <div class="column-item-text">
              {{ $t('homePage.label[9]') }}
            </div>
          </div>
          <div class="column-item">
            <img src="../assets/images/rank-relation.png" alt="rank" width="80" height="80" style="text-align: center; margin: 0px auto; display: block;">
            <div class="column-item-title">
              {{ $t('homePage.label[10]') }}
            </div>
            <div class="column-item-text">
              {{ $t('homePage.label[11]') }}
            </div>
            <div class="column-item-text">
              {{ $t('homePage.label[12]') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map-div" style="margin: -260px auto 0px; width: 100%; padding-top: 30px;">
      <div>
        <div class="map-wrap" style="margin-top: 100px;width: 100%;">
          <img src="../assets/images/map.png" alt="map" style="width: 100%;">
          <div class="country american">
            <img src="../assets/images/map-flag-american.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[0]') }}</div>
          </div>
          <div class="country agenting">
            <img src="../assets/images/map-flag-agenting.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[1]') }}</div>
          </div>
          <div class="country brazil">
            <img src="../assets/images/map-flag-brazil.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[2]') }}</div>
          </div>
          <div class="country britain">
            <img src="../assets/images/map-flag-britain.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[3]') }}</div>
          </div>
          <div class="country vietnam">
            <img src="../assets/images/map-flag-vietnam.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[4]') }}</div>
          </div>
          <div class="country hongkong">
            <img src="../assets/images/map-flag-hongkong.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[5]') }}</div>
          </div>
          <div class="country russia">
            <img src="../assets/images/map-flag-russia.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[6]') }}</div>
          </div>
          <div class="country japan">
            <img src="../assets/images/map-flag-japan.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[7]') }}</div>
          </div>
          <div class="country south-korea">
            <img src="../assets/images/map-flag-southkorea.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[8]') }}</div>
          </div>
          <div class="country singapore">
            <img src="../assets/images/map-flag-singapore.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[9]') }}</div>
          </div>
          <div class="country dubai">
            <img src="../assets/images/dubai.png" alt="country" class="map-img">
            <div class="country-name">{{ $t('homePage.country[10]') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="business-div">
      <div class="business-title">{{ $t('homePage.trade[0]') }}</div>
      <div class="business-container">
        <div class="business-column">
          <div class="business-item">
            <div style="width: 100%; height: 70px;display: flex;justify-content: center;">
              <img src="../assets/images/business-trade.png" align="right" alt="businese trade" style="width: 70px; height: 70px;">
            </div>
            <div class="business-trade-title">{{ $t('homePage.trade[1]') }}</div>
            <div class="trade-text">{{ $t('homePage.trade[2]') }}</div>
            <div class="trade-text">{{ $t('homePage.trade[3]') }}</div>
            <div class="trade-text">{{ $t('homePage.trade[4]') }}</div>
          </div>
        </div>
        <div class="business-column">
          <img :src="settings.domain ? settings.domain + settings.bottom_img : ''" alt=" trade image" width="292" height="614" style="margin-left: 50px; margin-right: 50px;">
        </div>
        <div class="business-column">
          <div class="business-item">
            <div style="width: 100%; height: 70px; display: flex;justify-content: center;">
              <img src="../assets/images/notes.png" align="left" alt="businese notes" style="width: 70px; height: 70px;">
            </div>
            <div class="business-trade-title">{{ $t('homePage.trade[6]') }}</div>
            <div class="trade-text">{{ $t('homePage.trade[7]') }}</div>
            <div class="trade-text">{{ $t('homePage.trade[8]') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="download-app-block">
        <div class="download-app-title">
          {{ $t('homePage.download[0]', { appName: settings.name }) }}
        </div>
        <div class="download-app-desc">
          {{ $t('homePage.download[1]') }}
        </div>
        <div style="padding: 0 30px;">
          <div class="download-app-links">
          <div class="hoverable download-link"  @click="openLink(appLink)">
            <img src="../assets/images/download-qrcode.png" alt="download qrcode" height="70" width="70" style="margin-bottom: 20px;">
            <div class="">
              iOS & Android
            </div>
          </div>
          <div  class="download-link" @click="openLink(appLink)">
            <img src="../assets/images/download-ios.png" alt="download ios" height="70" width="70" style="margin-bottom: 20px;">
            <div>
              iPhone
            </div>
          </div>
          <div  class="download-link" @click="openLink(appLink)">
            <img src="../assets/images/download-android.png" alt="download android" height="70" width="70" style="margin-bottom: 20px;">
            <div>
              Android
            </div>
          </div>
        </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import News from '../components/News'
// import { ArrowRightOutlined } from '@ant-design/icons-vue'
import {defineComponent, ref, inject, onMounted, getCurrentInstance, watchEffect } from 'vue'
import klineMockData from '../mock/kline'
// import Line from '../components/Line'
import axios from 'axios'
import { config } from '@/common/config.js'

// const domain = 'admin.bnb-dt.com'

async function fetchBanner(headers) {
  const url = `https://${config.domain}/api/news/list?category_id=99`
  return await axios.get(url, { headers })
}

export default defineComponent({
  name: 'Home',
  // components: { News, ArrowRightOutlined, Line },
  components: { News },
  setup() {
    let bannerList = ref([])
    let maxNum = 1200 * 10000
    let minNum = 1000 * 10000
    let endValue = (minNum + Math.random() * (maxNum - minNum)).toFixed(0)
    endValue = Number(endValue)
    let startValue = 0
    let volume = ref(0)
    let printValue = 0
    let startTime = null
    let remainTime = 3000 // 持续时间 2000毫秒

    function requestAnimation(callback) {
      let currTime = new Date().getTime()
      const timeToCall = Math.max(0, 16 - (currTime - remainTime));
      let id = setTimeout(() => {
        callback(currTime + timeToCall)
      }, timeToCall)
      return id
    }

    function count(timestamp) {
      if (!startTime) startTime = timestamp
      let progress = timestamp - startTime
      if (endValue) {
        printValue = startValue + (endValue - startValue) * (progress / remainTime)
      }
      printValue = Number(printValue.toFixed(0))
      // volume.value = endValue > printValue ? printValue : endValue
      volume.value =7104861639
      if (progress < remainTime) {
        requestAnimation(count)
      }
    }

    function generateVolume() {
      endValue = Number((minNum + Math.random() * (maxNum - minNum)).toFixed(0))
      startTime = null
      printValue = 0
      requestAnimation(count)
    }
    const { ctx } = getCurrentInstance()
    onMounted(async () => {
      const headers = {
        lang: ['tc', 'cn'].indexOf(ctx.$i18n.locale) > -1 ? 'ft' : 'en'
      }
      const res = await fetchBanner(headers)
      if (res.status === 200) {
        const data =  res.data.data
        bannerList.value = data?.news?.data || []
      }
    })

    // 获取applink
    const appLink = inject('downdloadLink', ref(''))
    const settings = inject('settings', ref({}))

    // 数据
    watchEffect(() => {
      let webtitle = '{appName} | Bitcoin, Ripple, Ethereum and more with Trust and Safety'
      if (settings.value.name) {
        document.title = webtitle.replace(/{appName}/, settings.value.name)
      }
    })
    return { volume, generateVolume, appLink, settings, bannerList}
  },
  mounted() {
    this.generateVolume()
    const _this = this
    let id = setInterval(() => {
      _this.generateVolume()
    }, 1000 * 60 * 5) // 5分钟执行一次
    this.timerId = id
    this.mockData = klineMockData.data
    this.fetchCurrency()
    this.connect()
  },
  unmounted() {
    if (this.timerId) {
      clearInterval(this.timerId)
    }
    if (this.ws) {
      this.ws.close()
    }
  },
  methods: {
    onChange() {
    },
    connect() {
      const createList = ['BNT/USDT']
      const _this = this
      // 创建 webSocket 链接
      let ws = new WebSocket(`wss://${config.domain}/ws`)
      this.ws = ws
      // 链接 打开之后
      ws.addEventListener('open', function() {
        const data = JSON.stringify({
          type: 'sub',
          params: "DAY_MARKET"
        })
        for (let item of createList ) {
          const param = JSON.stringify({ type: 'sub', params: `VOLUME.${item}` } )
          ws.send( param )
        }
        ws.send( data )
      })
      ws.addEventListener('message', function (event) {
        if (event.data) {
          const data = JSON.parse(event.data)
          switch (data.type) {
            case 'DAY_MARKET': {
              let flag = false; // 是否是自发币flag
              if (createList.findIndex(symbol => symbol === data.symbol) > -1) {
                // 去掉自发币的volume
                flag = true
              }
              _this.currencyMatchList.map(item => {
                if (data.currency_match_id == item.id) {
                  if (flag) {
                    data.quotation.volume = item.currency_quotation.volume
                  }
                  item.currency_quotation = data.quotation
                  item.symbol = data.symbol
                }
                return item
              })
              break
            }
            case 'VOLUME': {
              let target = _this.currencyMatchList.find(item => data.currency_match_id === item.id)
              if (target) {
                target.currency_quotation.volume = data.volume
                // _this.$set(target.currency_quotation, 'volume', data.volume)
              }
              break
            }
          }
        }
      })

      ws.addEventListener('close', function() {
      })
    },
    fetchCurrency() {
      const path = `https://${config.domain}/api/market/currency_matches`
      axios.get(path).then(res => {
        if (res.status == 200) {
          const data = res.data.data
          let currencyDetail = data.find(item => item.code == 'USDT')
          let list = currencyDetail.matches
          this.currencyMatchList = list
        }
      })
    },
    formatChange(change) {
      if (change.startsWith('-')) return change
      return `+${change}`
    },
    getVolumeText(val) {
      let num = (val + '').split('')
      let res = ''
      let len = num.length
      for (let i = 0; i < len; i++) {
        if ((i + 1)  % 3 == 0) {
          res = `${res},`
        }
        res = res + num[i]
      }
      return '$' + res
    },
  },
  data() {
    return {
      timerId: null,
      mockData: [],
      currencyMatchList: [],
      list: [
        {
          currency: 'XRP',
          symbol: 'XRP/USDT',
          price: '0.52518',
          price_cny: '3.3927',
          change: '-1.2%',
          volume: '35,853,337.432',
        },
        {
          currency: 'GUSDT',
          symbol: 'GUSDT/USDT',
          price: '44.2000',
          price_cny: '285.6185',
          change: '+1.49%',
          volume:  '19,348,716.513',
        },
        {
          currency: 'LIT',
          symbol: 'LIT/USDT',
          price: '10.6135',
          price_cny: '68.5702',
          change: '-7.05%',
          volume:  '17,089,497.247',
        },
        {
          currency: 'OMG',
          symbol: 'OMG/USDT',
          price: '6.0442',
          price_cny: '39.0231',
          change: '-2.05%',
          volume:  '77,470,487.158',
        },
        {
          currency: 'CRV',
          symbol: 'CRV/USDT',
          price: '2.6496',
          price_cny: '17.1186',
          change: '-1.05%',
          volume:  '77,470,487.158',
        },
        {
          currency: 'HYPE',
          symbol: 'HYPE/USDT',
          price: '5.7321',
          price_cny: '37.0400',
          change: '-1.05%',
          volume:  '987,879.36',
        },
      ]
    }
  }
})

</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

@media screen and (max-width: 1560px) {
  .home .banner-item {
    height: 350px!important;
  }
  .ant-carousel {
    height: 350px!important;
  }
}

@media screen and (max-width: 1343px) {
  .home .banner-item {
    height: 180px!important;
  }
  .ant-carousel {
    height: 180px!important;
  }
}

.ant-carousel .slick-dots-bottom {
  bottom: 65px!important;
}
.ant-carousel {
  height: 430px;
}
.home {
  position: relative;
  .banner-dot {
    button {
      width: 8px!important;
      height: 8px!important;
      border-radius: 50%!important;
      margin-right: 5px;
    }
  }
  .banner-item {
    height: 430px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  .download-app-block {
    background-color: #fff;
    padding: 100px 0;
    height: 100%;
    .download-app-title {
      text-align: center;
      font-weight: 500;
      font-size: 40px;
      line-height: 1.2;
    }
    .download-app-desc {
      padding: 0 20px;
      margin-top: 20px;
      margin-bottom: 60px;
      font-size: 16px;
      line-height: 1.2;
    }
    .download-app-links {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 100%;
      .download-link {
        cursor: pointer;
      }
    }
  }

  .business-div {
    height: 100%;
    width: 100%;
    margin-top: 70px;
    background: url("../assets/images/brand.png") no-repeat;
    background-size: cover;
    font-size: 16px;
    .business-title {
      text-align: center;
      // margin-left: 20px;
      padding-top: 80px;
      padding-bottom: 60px;
      font-family: PingFang-SC;
      line-height: 1.2;
      font-size: 30px;
      white-space: nowrap;
      color: #fff;
    }

    .business-container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      .business-column {
        .business-item {
          text-align: center;
          color: #fff;
          width: 100%;
          height: 292px;
          cursor: pointer;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 30px;
          &:hover {
            background-color: hsla(0,0%,100%,.1);
          }
          .business-trade-title {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 18px;
            &.align-left {
              text-align: left;
            }
            &.align-right {
              text-align: right;
            }
          }
          .trade-text {
            text-align: center;
            opacity: 0.8;
            &.align-left {
              text-align: left;
            }
            &.align-right {
              text-align: right;
            }
          }
          .trade-now {
            color: $brandColor;
            &.align-left {
              text-align: left;
            }
            &.align-right {
              text-align: right;
            }
            margin-top: 10px;
          }
        }
      }
    }
  }

  .map-div {
    width: 100%;
    height: 700px;
    margin: 0 auto;
    overflow: hidden;
    .map-wrap {
      margin-top: 100px;
      position: relative;
      width: 1110px;
      margin: -260px auto 0px;
      .country {
        float: left;
        position: absolute;
        .map-img {
          width: 80px;
          height: 69px;
          float: left;
          margin: -15px 0 0 -10px;
        }
        .country-name {
          opacity: 0.7;
          font-family: PingFang-SC;
          float: left;
          font-size: 12px;
          //margin: 5px 0 0 -10px;
        }
        &:before {
          float: left;
          display: inline-block;
          content: "";
          width: 16px;
          height: 16px;
          background: url("../assets/images/map-flag.png") no-repeat 50%;
          background-size: 100%;
          margin: 3px 0 0 -10px;
          animation: slidein .6s linear 1s infinite alternate;
        }
        &.american {
          left: 200px;
          top: 600px;
        }
        &.agenting {
          left: 310px;
          top: 540px;
        }
        &.brazil {
          left: 90px;
          top: 540px;
        }
        &.britain {
          left: 310px;
          top: 285px;
        }
        &.vietnam {
          right:  190px;
          top: 285px;
        }
        &.hongkong {
          right: 210px;
          top: 375px;
        }
        &.russia {
          right: 150px;
          top: 220px;
        }
        &.japan {
          right: 120px;
          top: 340px;
        }
        &.south-korea {
          left: 30px;
          top: 210px;
        }
        &.singapore {
          right: 250px;
          top: 440px;
        }
        &.dubai {
          left: 200px;
          top: 430px;
        }

      }
    }
  }

  .bg-body {
    background: linear-gradient(180deg,#f7f7f7,#fff)!important;
    .bg-body-column {
      display: flex;
      flex-direction: column;
      height: 100%;
      .column-text-title {
        font-size: 30px;
        line-height: 1.2;
        color: #000;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .column-title {
        padding-top: 10px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        .text-title {
          font-size: 30px;
          line-height: 1.2;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        span {
          margin-left: 15px;
          font-size: 40px;
          line-height: 1.2;
          background-image: linear-gradient(90deg,#fe9607,#fcbc32);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .column-text {
        font-size: 16px;
        color: #000;
        font-family: Microsoft YaHei;
      }
    }
    .bg-body-column2 {
      display: flex;
      height: 100%;
      .column-item {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        max-height: 100%;
        .column-item-title {
          margin-top: 20px;
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 1.2;
          color: #000;
          font-weight: 500;
        }
        .column-item-text {
          maegin-top: 5px;
          opacity: 0.7;
        }
      }
    }
  }

  .page-markets {
    .market-range {
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      color: #b9c0e0;
      overflow-x: hidden;
      .banner-tickers {
        flex: 1;
        //flex-basis: 0;
        //flex-grow: 1;
        background: #151826!important;
        display: flex;
        // overflow: auto;
		overflow: hidden;

        .banner-ticker-item {
          flex-basis: 0;
          flex-grow: 1;
          padding-top: 30px;
          padding-bottom: 30px;
          cursor: pointer;
          user-select: none;
width:220px;
          &:hover {
            background: #212436!important;
          }
          .banner-ticker-wrap {
            padding-left: 5px;
            padding-right: 30px;
            display: flex;
            justify-content: space-between;
            border-right: 1px solid rgba(124,136,160,.24);
            .banner-ticker-column {
              .symbol-name {
                margin-bottom: 10px;
                font-size: 14px;
                display: flex;
                align-items: center;
              }
              .symbol-price {
                padding-left: 25px;
                color: #fff;
                font-weight: 600;
                font-size: 1.45rem!important;
                text-align: left;
                margin-bottom: 10px;
              }
              .symbol-volume {
                padding-left: 25px;
                text-align: left;
              }
              .banner-ticker-change {
                color: #35b65a;
                margin-bottom: 35px;
                &.bg-up {
                  color: #00ab8d!important;
                }
                &.bg-down {
                  color: #e3595d!important;
                }
              }

            }
          }
        }
      }
    }
    .market-top {
      .top-container {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 100;
        .home-panel {
          background: #fff;
          border-radius: 4px;
          padding-top: 30px;
          box-shadow: 0 10px 20px 0 rgba(156 169 181 / 18%);
          border: 1px solid rgba(124,136,160,.1);
          .home-panel-title {
            font-size: 20px;
            line-height: 1.2;
            color: #000;
            text-align: left;
            padding: 0 40px;
          }
          .symbol-list {
            height: auto;
            .panel-subheader {
              display: flex;
              padding: 20px 40px 10px 40px;
              align-items: center;
              position: relative;
              font-size: 12px;
              color: rgba(0,0,0,.45);
              .head-subs {
                display: flex;
                position: relative;
                flex-basis: 0;
                flex-grow: 1;
                .subheader-item {
                  flex-basis: 0;
                  flex-grow: 1;
                  font-size: 12px;
                  color: rgba(0,0,0,.45);
                  text-align: left;
                }
              }
              .subfunc {
                text-align: right;
              }
            }

            .symbol-list-item {
              transition: transform .5s,-webkit-transform .5s;
              background-color: #fff;
              border: none;
              padding-left: 40px;
              padding-right: 40px;
              display: flex;
              &:hover {
                box-shadow: 0 10px 20px 0 rgb(156 169 181 / 18%);
                border: 1px solid #edf0f3;
                transform: scale(1.03125);
                z-index: 9;
              }
              .symbol-list-item-container {
                display: flex;
                background-color: #fff;
                border-bottom: 1px solid #f2f2f2;
                cursor: pointer;
                align-items: center;
                flex-basis: 0;
                flex-grow: 1;
                .list-item-wrap {
                  flex-basis: 0;
                  flex-grow: 1;
                  display: flex;
                  .ticker-item {
                    overflow: hidden;
                    text-overflow: ellipsis!important;
                    white-space: nowrap!important;
                    flex-basis: 0;
                    flex-grow: 1;
                    text-align: left;
                    .ticker-label {
                      margin: 0 10px;
                      border-radius: 4px;
                      height: 32px;
                      // width: 90px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      &.bg-up {
                        color: #00ab8d!important;
                        background-color: rgba(0,171,141,.1);
                      }
                      &.bg-down {
                        color: #e3595d!important;
                        background-color: rgba(227,89,93,.1);
                      }
                    }
                  }
                }
                .subfunc {
                  font-size: 14px;
                  color: #3cbc98;
                }
              }
            }
          }
          .show-more {
            height: 65px;
            background-color: #fff;
            color: #f5bb05;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              margin-right: 10px;
              &:hover {
                opacity: .7;
              }
            }
          }
        }
      }
    }
  }
}
</style>
