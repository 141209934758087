import { createStore } from 'vuex'

export default createStore({
  state: {
    domain: ''
  },
  mutations: {
    setDomain(state, val) {
      state.doamin = val
    }
  },
  getters: {
    getHost: (state) => {
      return state.domain
    }
  },
  actions: {
  },
  modules: {
  }
})
