import { createApp } from 'vue'
import App from './App.vue'
import AppPc from './AppPc.vue'
import router from './router'
import store from './store'
import Anted from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './assets/styles/common.css'
import { createI18n } from 'vue-i18n'
import Chinease from './common/locales/zh'
import English from './common/locales/en'
import TC from './common/locales/tc'
import mixin from './common/mixin'
import { config } from './common/config'

let lang = (navigator.browserLanguage || navigator.language).toLowerCase()

let localLang;

switch (lang) {
    case 'zh-cn':
    case 'zh-tw':
    case 'zh-hk':
        localLang = 'en'
        break
    default:
        localLang = 'tc'
        break
}


const i18n = createI18n({
    locale: localStorage.getItem(config.lang_storage_key) ||  localLang,
    messages: {
        cn: Chinease,
        en: English,
        tc: TC, // Traditional Chinese
    }
})

let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

if(flag) {
    createApp(App)
        .use(store)
        .use(router)
        .use(Anted)
        .use(i18n)
        .mixin(mixin)
        .mount('#app')
} else {
    createApp(AppPc)
    .use(store)
    .use(router)
    .use(Anted)
    .use(i18n)
    .mixin(mixin)
    .mount('#app')
}

