module.exports = {
    data: [
        {
            symbol: 'BTC/USDT',
            data: ["47025.76000000","47086.68000000","48552.95000000","47956.95000000","48960.85000000","51135.55000000","51988.77000000"],
            price: '51969.25',
            volume: '335529.8814',
            change: '1.66',
            icon: require('../assets/images/BTC.png')
        },
        {
            symbol: 'ETH/USDT',
            data: ["1788.88000000","1815.87000000","1799.41000000","1810.40000000","1773.66000000","1810.38000000","1899.32000000"],
            price: '1899.99',
            volume: '12268.2399',
            change: '4.95',
            icon: require('../assets/images/ETH.png')
        },
        {
            symbol: 'GUSDT/USDT',
            data: ["42.68800000","44.19000000","49.75210000","44.50000000","43.57980000","44.59900000","44.87010000"],
            price: '44.9',
            volume: '289.9597',
            change: '0.63',
            icon: require('../assets/images/GUSDT.png')
        },
        {
            symbol: 'DOT/USDT',
            data: ["28.35670000","28.80160000","26.78040000","29.27750000","29.82020000","30.73100000","31.63080000"],
            price: '31.6730',
            volume: '204.7464',
            change: '3.12',
            icon: require('../assets/images/DOT.png')
        },
        {
            symbol: 'BCH/USDT',
            data: ["543.91000000","595.56000000","670.33000000","739.03000000","700.80000000","715.12000000","716.22000000"],
            price: '712.29',
            volume: '4600.2831',
            change: '-0.42',
            icon: require('../assets/images/BCH.png')
        },
        {
            symbol: 'LTC/USDT',
            data: ["183.93000000","214.87000000","212.55000000","211.62000000","209.89000000","229.49000000","230.47000000"],
            price: '229.51',
            volume: '1483.5255',
            icon: require('../assets/images/LTC.png')
        }
    ],
}
