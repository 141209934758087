import pkg from '../../package.json'

export default {
    data() {
        return {
            // appName: pkg.name,
            version: pkg.version,
        }
    },
    methods: {
        openLink(link) {
            window.open(link)
        }
    }
}
