<!-- <template>
  <div class="news-wrap" style="display: :none;">
    <div class="article-news">NEW</div>
    <a class="article-news-content" href="javascript: ;">
      {{ lang == 'tc' ? settings[`notice_cn`] : settings[`notice_${lang}`] }}
    </a>
    <a class="article-news-more" href="javascript: ;">{{ $t('news.default[0]') }}</a>
  </div>
</template> -->

<script>
import {ref, inject, getCurrentInstance, watchEffect, watch} from 'vue'
export default {
  name: "News",
  props: {},
  setup(props) {
    console.log(props)
    const settings = inject('settings', ref({}))
    watchEffect(() => {
      console.log(settings.value)
    })
    const proxy = getCurrentInstance()
    let lang;
    watch(proxy, () => {
      lang = proxy.ctx.$i18n.locale
    })

    console.log(lang)
    return { settings, lang }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';
.news-wrap {
  position: absolute;
  background: rgba(0, 0, 0, 0.12);
  height: 50px;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px!important;
  z-index: 100;
  .article-news {
    width: 38px;
    height: 18px;
    border-radius: 4px;
    background-color: $brandColor;
    font-size: 12px;
  }
  .article-news-content {
    color: #fff;
    margin: 0 20px 0 10px;
  }
  .article-news-more {
    color: $brandColor;
  }
}
</style>
