<template>
  <div id="nav">
    <div class="navbar">
      <div class="nav-logo-wrap">
        <router-link to="/">
          <img class="nav-logo" :src="domain + settings.menuBarLogo" alt="home logo" height="40">
        </router-link>
      </div>
      <div class="navbar-menus-wrapper">
        <div class="navbar-menus-item">
        </div>
      </div>
      <div class="download-module">
        <a-dropdown @visibleChange="onVisibleChange" :overlayStyle="{width:'100%'}">
          <div class="download-module-wrap ant-dropdown-link">
            <img src="./assets/images/icon-phone.png" alt="" style="height: 18px;" class="img-hover-hidden" v-if="!downloadVisible">
            <img src="./assets/images/icon-phone-fill.png" alt="" style="height: 18px;" class="img-hover-visible" v-else>
          </div>
          <template #overlay >
            <div class="navbar-download-layer" style="width: 100%;">
              <div class="navbar-download-wrap">
                <div class="navbar-download-column qrcode">
                  <div class="navbar-download-qrcode">
                    <qrcode :url="downloadUrl"></qrcode>
                  </div>
                  <div class="qrcode-text">
                    iOS & Android
                  </div>
                </div>
                <div class="navbar-download-column download-buttons">
                  <a-button class="navbar-download-button" type="primary" @click="openLink(downloadUrl)">
                    <div class="button-inner-wrap">
                      <AppleFilled class="button-inner-icon" width="26em" height="26em"/>
                      <div class="button-inner">
                        <div>Download APP</div>
                        <div>Apple</div>
                      </div>
                    </div>
                  </a-button>
                  <a-button class="navbar-download-button" type="primary" @click="openLink(downloadUrl)">
                    <div class="button-inner-wrap">
                      <AndroidFilled class="button-inner-icon" width="26em" height="26em"/>
                      <div class="button-inner">
                        <div>Download APK</div>
                        <div>Android</div>
                      </div>
                    </div>
                  </a-button>
                </div>
              </div>
            </div>
          </template>
        </a-dropdown>
      </div>
      <div class="split-module">
        <div class="split-item">|</div>
      </div>
      <div class="switch-language">
        <a-dropdown>
          <div class="ant-dropdown-link language" @click.prevent>
            {{ languageList[activeIndex].name }}
            <DownOutlined />
          </div>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="(item, i) in languageList" :key="i" :data-index="i" @click="onLanguageSwitch">
                <a href="javascript:;">{{ item.name }}</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
  <router-view/>
  <!-- <Footer /> -->
</template>

<script>
// import { DownOutlined, AppleFilled, AndroidFilled } from '@ant-design/icons-vue'
import { defineComponent, ref, getCurrentInstance, onMounted, provide  } from 'vue'
// import Footer from './components/Footer'
// import qrcode from './components/qrcode'
import axios from 'axios'
import { config } from '@/common/config.js'

const HOST = `https://${config.domain}/`

export default defineComponent({
  // components: { DownOutlined, Footer, AppleFilled, AndroidFilled, qrcode },
  // components: { DownOutlined, Footer, AppleFilled, AndroidFilled, qrcode },
  setup() {
    const proxy = getCurrentInstance()
    let downloadVisible = ref(false)
    function onVisibleChange(visible) {
      downloadVisible.value = visible
    }
    let languageList = [
      {
       id: new Date().getTime(),
       name: 'English',
       type: 'en' 
      },
      {
        id: new Date().getTime(),
        name: '繁體中文',
        type: 'tc'
      }
    ]
    let activeIndex = ref(0)
    let lang = (navigator.browserLanguage || navigator.language).toLowerCase()
    lang = localStorage.getItem(config.lang_storage_key) || lang
    switch (lang) {
      case 'zh-cn':
      case 'zh-tw':
      case 'zh-hk':
      case 'tc':
        activeIndex.value = 1
        break
      case 'en':
        activeIndex.value = 0
        break
      default:
        activeIndex.value = 1
        break
    }
    let onLanguageSwitch = function(e) {
      const key = e.key;
      activeIndex.value = key;
      proxy.ctx.$i18n.locale = languageList[key].type
      localStorage.setItem(config.lang_storage_key, languageList[key].type)
      location.reload()
    }

    // fetch settings
    const settings = ref({})
    const downloadUrl = ref('')
    const domain = ref('')
    provide('downdloadLink', downloadUrl)
    provide('settings', settings)
    provide('domain', domain)
    onMounted(async () => {
      settings.value = await fetchSettings()
      createFavicon(settings.value.logo)
      downloadUrl.value = settings.value.download_url
      domain.value = settings.value.domain
    })

    return { downloadVisible, onVisibleChange, languageList, onLanguageSwitch, activeIndex, settings, downloadUrl, domain }
  },
  methods: {
    openLink() {
      if (this.downloadUrl) {
        window.open(this.downloadUrl)
      }
    },
    handleMenuClick() {
      let locale = this.$i18n.locale
      let url = ''
      let paper = ''
      if (locale == 'tc' || locale == 'cn') {
        paper = this.settings.cn_white_pager.replace('/', '')
        url = this.domain + paper
      } else {
        paper = this.settings.en_white_paper.replace('/', '')
        url = this.domain + paper
      }
      window.open(url)
    }
  }
})


async function fetchSettings() {
  const { data } = await axios.get(`${HOST}/api/office_data`)
  if (data && data.code == 1) {
    return data?.data || {}
  }
  return {}
}

function createFavicon(link) {
  let favicon = document.querySelector('link[rel="ico"]')
  if (favicon !== null) {
    favicon.href = link
  } else {
    favicon = document.createElement("link")
    favicon.rel = "icon"
    favicon.href = `${HOST}${link.replace('/', '')}`
    document.head.appendChild(favicon)
  }
}

</script>

<style lang="scss" scoped>
@import './assets/styles/variables';
* {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

a {
  text-decoration: none;
  outline-style: none;
  transition: color .3s;
  &:hover {
    color: #f5bb05;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  position: sticky;
  top: 0;
  background-color: #151826!important;
  width: 100%;
  height: auto;
  z-index: 1000;
  .navbar {
    height: 50px;
    width: 100%;
    display: flex;
    padding: 0 15px;
    .nav-logo-wrap {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      //height: 3.125rem!important;
      .nav-logo {
      }
    }
    .navbar-menus-wrapper {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      display: flex;
      align-item: center;
      padding-left: .625rem!important;
      //height: 3.125rem!important;
      font-weight: 400;
      .navbar-menus-item {
        padding: 0 10px;
        display: flex;
        align-items: center!important;
        justify-content: center;
        a {
          color: #fff;
          font-size: 14px;
          height: 100%!important;
          line-height: 1.2;
          display: flex;
          align-items: center!important;
          justify-content: center;
          &:hover {
            color: $brandColor;
          }
        }
      }
    }
    .switch-language {
      height: 100%;
      .language {
        height: 100%;
        display: flex;
        align-items: center;
        color: #fff;
        cursor: pointer;
        &:hover {
          color: $hoverColor;
        }
      }
    }
    .login-module {
      padding: 0 10px;
      display: flex;
      align-items: center;
      .login-item {
        color: #fff;
        cursor: pointer;

        &:hover {
          color: $brandColor;

        }
      }
      .register-item {
        color: #fff;
        cursor: pointer;
        &:hover {
          background: #f5bb05!important;
          color: #000!important;
        }
        height: 28px;
        border: 1px solid $brandColor;
        border-radius: 4px;
        padding: 0 15px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .split-module {
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .split-item {
        opacity: .3;
      }
    }
    .download-module {
      padding: 0 10px;
      display: flex;
      align-items: center;
      position: relative;
      .download-module-wrap {
        cursor: pointer;
      }
    }
  }
}
.navbar-download-layer {
  width: 100%;
  background: #fff!important;
  border-radius: 4px;
  padding: 30px;
  margin-top: -9px;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 8%);
  margin-top: 9px;
  .navbar-download-wrap {
    display: flex;
  }
  .navbar-download-column {
    max-width: 100%;
    &.download-buttons {
      flex-basis: 0 ;
      flex-grow: 1;
      max-width: 100%;
    }
    &.qrcode {
      margin-right: 30px;
      .navbar-download-qrcode {
        margin-bottom: 10px;
      }
      .qrcode-text {
        font-size: 12px;
        color: rgba(0, 0, 0, .7);
        text-align: center;
      }
    }
    .navbar-download-button {
      display: flex;
      background: transparent;
      border-color: #f5bb05!important;
      color: #f5bb05!important;
      border-radius: 4px;
      margin: 0;
      width: 100%;
      height: 48px;
      align-items: center;
      padding: 0 0;
      position: relative;
      font-size: 14px;
      margin-bottom: 15px;
      &:hover {
        background: #f5bb05!important;
        border-color: #f5bb05!important;
        color: #000!important;
      }
      .button-inner-wrap {
        position: relative;
        width: 100%!important;
        display: flex;
        align-items: center;
        padding-left: 50px;
        .button-inner-icon {
          margin-right: 15px;
          svg {
            font-size: 26px;
          }
        }
        .button-inner {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          div {
            line-height: 1;
          }
        }
      }
    }
    .navbar-download-qrcode {
      padding: 5px;
      border: 1px solid rgba(124, 136, 160, 0.34);
    }
  }
}
  >>>.ant-dropdown {
    width: 100% !important
  }
</style>
