import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import HomePc from '../views/HomePc.vue'
let HomePage = Home
let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) 
if(flag) {
  HomePage = Home
} else {
  HomePage = HomePc
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      title: 'IDC | Bitcoin, Ripple, Ethereum and more with Trust and Safety'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.meta?.title) {
//     document.title = to.meta.title
//   }
//   next()
// })

export default router
