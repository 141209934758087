module.exports = {
    banner: {
        default: [
            '白皮書',
            '登錄',
            '注冊',
        ]
    },
    footer: {
        default: [
            '聯係我們',
            '客服郵箱: {address}',
            '上幣合作: {address}',
            '加入我們: {address}',
            '站點',
            '國際站',
            '巴西',
            '全球最具信賴的數字資產交易平臺',
            '有限公司',
        ]
    },
    news: {
        default: [
            '更多',
            '{appName}首發上綫EVAL DEFI (EVF)的公告'
        ],
    },
    homePage: {
        currencyRank: [
            '熱門榜',
            '幣種',
            '交易對',
            '最新價',
            '漲跌幅',
            '24H成交量',
            '操作',
            '交易',
            '查看更多'
        ],
        label: [
            '{appName}全球站',
            '近24小時成交量',
            '觸達全球超180個國家，為數百萬用戶提供安全、可信賴的數字資產交易及資產管理服務',
            '全球優質交易所',
            'CoinMarketCap 權威認證排名',
            '100萬單/秒高性能引擎撮合系統',
            '3年老牌交易所',
            '500萬+注冊用戶',
            'HSM銀行級安全防護',
            '專業分佈式架構、防 DDOS 攻擊系統',
            '國際化',
            '全球生態佈局',
            '設立本土個性化運營服務'
        ],
        country: [
            '美國',
            '阿根廷',
            '巴西',
            '英國',
            '越南',
            '香港',
            '俄羅斯',
            '日本',
            '韓國',
            '新加坡',
            '迪拜'
        ],
        trade: [
            '多業務交易',
            '幣幣交易',
            '7*24小時在綫交易',
            '低點差 高流動性',
            '更多優質新幣首發',
            '立即交易',
            '期權交易',
            '低手續費率，高做市佣金',
            '全球首家合夥人交易平臺',
        ],
        download: [
            '{appName}官方App下載',
            '覆蓋iOS、Android、Google Play多個平臺，隨時隨地掌上交易',
        ]
    }
}
