module.exports = {
    banner: {
        default: [
            '白皮书',
            '登录',
            '注册',
        ]
    },
    footer: {
        default: [
            '联系我们',
            '客服邮箱: {address}',
            '上币合作: {address}',
            '加入我们: {address}',
            '站点',
            '国际站',
            '巴西',
            '全球最具信赖的数字资产交易平台',
            '有限公司',
        ]
    },
    news: {
        default: [
            '更多',
            '{appName}首发上线EVAL DEFI (EVF)的公告'
        ],
    },
    homePage: {
        currencyRank: [
            '热门榜',
            '币种',
            '交易对',
            '最新价',
            '涨跌幅',
            '24H成交量',
            '操作',
            '交易',
            '查看更多'
        ],
        label: [
            '{appName}全球站',
            '近24小时成交量',
            '触达全球超180个国家，为数百万用户提供安全、可信赖的数字资产交易及资产管理服务',
            '全球优质交易所',
            'CoinMarketCap 权威认证排名',
            '100万单/秒高性能引擎撮合系统',
            '3年老牌交易所',
            '500万+注册用户',
            'HSM银行级安全防护',
            '专业分布式架构、防 DDOS 攻击系统',
            '国际化',
            '全球生态布局',
            '设立本土个性化运营服务'
        ],
        country: [
            '美国',
            '阿根廷',
            '巴西',
            '英国',
            '越南',
            '香港',
            '俄罗斯',
            '日本',
            '韩国',
            '新加坡',
            '迪拜'
        ],
        trade: [
            '多业务交易',
            '币币交易',
            '7*24小时在线交易',
            '低点差 高流动性',
            '更多优质新币首发',
            '立即交易',
            '期权交易',
            '低手续费率，高做市佣金',
            '全球首家合伙人交易平台',
        ],
        download: [
            '{appName}官方App下载',
            '覆盖iOS、Android、Google Play多个平台，随时随地掌上交易',
        ]
    }
}
